<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Global Settings
        </h1>
        <FormTemplate class="p-2" @response="formResponse" button="Save" method="post" action="/globalSettings/update" :formdata="item">
            <Checkbox label="Credit Card Expiration Cron" v-model="item.creditExpCron.value.value" :required="false" ></Checkbox>
        </FormTemplate>
    </div>
</template>

<script>
export default {
    name: 'Payments',
    mixins: [],
    data: () => ({
        item: {
            creditExpCron: { key: 'CreditExpirationCron', value: {value: false} },
        },
    }),
    computed: {
    },
    props: [],
    methods: {
        formResponse(re) {
            if(re.data.status == 'OK') {
                this.$notify({
                    title: 'Updated',
                    text: '',
                    type: 'success',
                });
            }
        },
        getSettings()
        {
            this.$requests.get('/globalSettings',
            {
                success: (re) => {
                    let value = re.data.settings.find(e => e.key == 'CreditExpirationCron')
                    if(value) this.item.creditExpCron.value.value = value.value
                    return true
                },
            });
        },
    },
    watch: {
    },
    created() {
        this.getSettings();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
