<template>
<div class="flex flex-col" >
    <div v-if="subscription" class="" >
        <button @click="subscription = false" class="bg-red px-4 py-2 border border-red text-white">
            Go Back
        </button>
        <Subscription :subscription="subscription" />
    </div>
    <div v-else class="" >
        <h2 class="text-red text-md">Subscriptions</h2>
        <ul class="p-2" v-for="(subscription,i) in subscriptions" :key="i">
            <li @click="ShowSubscription(subscription)" class="px-4 py-2 cursor-pointer border-b-2 hover:ring focus:ring">
                ID: {{subscription.id}} - Amount: $ {{subscription.amount}}
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import Subscription from './subscription/List'

export default {
    name: 'Subscriptions',
    data: () => ({
        subscriptions: [],
        subscription: false,
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        getSubscriptions() {
            if(this.model) {
                this.$requests.get(this.model.subscriptions_route+'/subscriptions/'+this.model.id,
                {
                    success: (re) => {
                        this.subscriptions = re.data.items
                        return true
                    },
                })
            }
        },
        ShowSubscription(subscription) {
            this.subscription = subscription
        },
    },
    watch: {
    },
    created() {
        this.getSubscriptions()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        Subscription,
    },
}
</script>
<style scoped>
</style>
