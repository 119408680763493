<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Payment
        </h1>
            <FormTemplate class="p-2" @response="formResponse" button="Charge" method="post" action="/payments/charge" :formdata="item">

                <SearchSelectInput @item:clicked="AgencyClick" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="false" label="Agency" v-model="item.agency" :optiondisplay="accountDisplay" :hideSelected="false" :required="true"></SearchSelectInput>

                <div class="m-4" v-if="item.agency">
                    <Checkbox label="Create New Payment Profile" v-model="item.new_payment" :required="false" ></Checkbox>
                    <FormTemplate v-if="item.new_payment" class="p-2" @response="formResponse" button="Create" method="post" action="/payment-profiles/create" :formdata="item">
                        <TextInput type="text" label="Card Number" v-model="item.cardNum" :required="false" ></TextInput>
                        <TextInput type="text" label="Expiration Date (yyyy-mm)" v-model="item.cardExp" :required="false" ></TextInput>
                        <TextInput type="text" label="First Name" v-model="item.fn" :required="false" ></TextInput>
                        <TextInput type="text" label="Last Name" v-model="item.ln" :required="false" ></TextInput>
                        
                        <TextInput type="text" label="Billing Address" v-model="item.billingAddress" :required="false" ></TextInput>
                        <TextInput type="text" label="Billing City" v-model="item.billingCity" :required="false" ></TextInput>
                        <TextInput type="text" label="Billing State" v-model="item.billingState" :required="false" ></TextInput>
                        <TextInput type="text" label="Billing Zip" v-model="item.billingZip" :required="false" ></TextInput>
                        <TextInput type="text" label="Billing Country" v-model="item.billingCountry" :required="false" ></TextInput>
                        <TextInput type="text" label="Billing Phone" v-model="item.billingPhone" :required="false" ></TextInput>
                    
                    </FormTemplate>
                    <SelectInput v-else @input="UpdatePaymentMethod" label="Payment Method" :required="false" :options="card_numbers"></SelectInput>
                </div>

                <TextInput type="text" label="Amount" v-model="item.amount" :required="true" ></TextInput>

                <label for="message">Message</label><br>
                <textarea id="message" class="w-full border-2" rows="5" type="textarea" v-model="item.message" :required="true" ></textarea>
                <br><br>

                
            </FormTemplate>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'
export default {
    name: 'Payments',
    mixins: [View],
    data: () => ({
        authdotnet: false,
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name','email','phone'],
            'action': '/search/',
        },
        item: {
            status: 'Active',
            unit: 'months',
            unit_length: 12,
            duration: 9999999,
            startDate: null,
            agency: null,
            cardNum: null,
            cardExp: null,
            fn: null,
            ln:null,
            new_payment: false,

            billingAddress: null,
            billingCity: null,
            billingState: null,
            billingCountry: null,
            billingZip: null,
            billingPhone: null,

            profile: null,
            method: null,
            message: null,
        },
        payment_methods: [],
        timeouts: {
            methods: false
        },
    }),
    computed: {

        card_numbers() {
            var cardNums = [];
            var methods = this.payment_methods;
            this.payment_methods.forEach( function(method, index) {
                cardNums[index] = { label: method.card_number, value: method.uid}
            })
            return cardNums;
        }
    },
    props: [],
    methods: {
        GetPaymentMethods() {
            console.log("GetPaymentMethods");
            this.$requests.get(`/agency/${this.item.agency.id}/payment_methods`,
            {
                success: (re) => {
                    console.log(re.data)
                    this.payment_methods = re.data.items;
                    this.UpdatePaymentMethod(this.payment_methods[0].uid);
                    return true
                },
            });
        },
        UpdatePaymentMethod(evt) {
            console.log("UpdatePaymentMethod");
            console.log(evt);
            this.item.method = evt;
            this.item.profile = this.getProfileID(evt);
        },
        accountSelected(item) {
            console.log("accountSelected");
            this.$set(this.item,'account_id',item.item.id)
            this.$set(this.item,'agency',item.item)

            if(this.timeouts.methods) clearTimeout(this.timeouts.methods)
            this.timeouts.methods = setTimeout(this.GetPaymentMethods,1000)
        },
        accountRemoved() {
            this.$delete(this.item,'account_id')
            this.$delete(this.item,'agency')
        },
        AgencyClick() {
            this.$router.push({path:'/agencies/'+this.item.account_id})
        },
        accountDisplay(item) {
            return 'ID: '+item.id+' - Name: '+item.name
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
        itemFetched() {
            if(this.item && this.item.uid && this.item.uid.trim() != ''){
                this.$requests.get(`/subscription/${this.item.id}/authdotnet/subscription`,
                {
                    success: (re) => {
                        this.authdotnet = re.data.item
                        return true
                    },
                })
            }
        },
        getProfileID(method_id)
        {
            return this.payment_methods.find( ({uid}) => uid == method_id ).payment_profile_id;
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
