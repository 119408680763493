<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Orders
        </h1>
        <LinkTemplate :to="{path:'/orders/create'}" class="my-6">
            Create New Order
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length || search != ''"  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/orders/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/orders/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'OrdersList',
    mixins: [List],
    data: () => ({
        limit: 10,
        page: 0,
        action: '/orders',
        searchParams: {
            'model': 'Order',
            'fields': ['id','total','account_id','user_id','payment_profile_id','payment_method_id','status'],
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'total',
                label:'Total',
                custom: 'DisplayTotal'
            },
            {
                id:'account_id',
                label:'Agency',
                custom: 'DisplayAgency'
            },
            {
                id:'user_id',
                label:'Ordered By',
                custom: 'DisplayCustomer'
            },
            {
                id:'payment_profile_id',
                label:'Payment Profile',
                custom: 'DisplayPaymentProfile'
            },
            {
                id:'payment_method_id',
                label:'Payment Method',
                custom: 'DisplayPaymentMethod'
            },
            {
                id:'status',
                label:'Status',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        DisplayPaymentProfile(item) {
            if(item.payment_profile) return item.payment_profile.uid + ' : ' + item.payment_profile.email
            return item.payment_profile_id
        },
        DisplayPaymentMethod(item) {
            if(item.payment_method) return item.payment_method.uid + ' : ' + item.payment_method.card_number
            return item.payment_method_id
        },
        DisplayTotal(item) {
            return '$'+parseFloat(item.total).toFixed(2)
        },
        DisplayAgency(item) {
            if(item.agency) return item.agency.id + ' : ' + item.agency.name
            return 'N/A'
        },
        DisplayCustomer(item) {
            if(item.customer) return item.customer.email
            return 'N/A'
        },
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
