<template>
<div class="my-2">
    <h2 class="text-red text-md">Payment Method<span v-if="payment_methods.length">s</span></h2>
    <div class="">
        <div v-if="payment_method" class="">
            <PaymentMethod :payment_method="payment_method" />
        </div>
        <div v-if="payment_methods.length" class="">
            methods
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import PaymentMethod from './PaymentMethod'

export default {
    name: 'PaymentMethods',
    data: () => ({
        payment_methods: [],
        payment_method: false,
    }),
    computed: {
    },
    props: ['model'],
    mixins: [],
    methods: {
        getPaymentMethods() {},
        getPaymentMethod(){
            this.$requests.get('/order/payment-method/'+this.model.id,
            {
                success: (re) => {
                    this.$set(this,'payment_method',re.data.item)
                    return true
                },
            })
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
        if(this.model) {
            this.getPaymentMethod()
        }else{
            this.getPaymentMethods()
        }
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        PaymentMethod,
    },
}
</script>
<style scoped>
</style>
