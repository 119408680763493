<template>
    <div class="container pt-16 px-2">
      <h1 class="text-red text-lg mb-6">
        PAP Members
      </h1>

      <div v-if="items.length"  class="">
        <TableTemplate v-if="loading == false" :candelete="false" :columns="columns" :items="items" canview="/agencies/"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
      </div>
      <div  v-else  class="">
        <TableTemplate v-if="loading == false" :candelete="false" :columns="columns" :items="pages[page]" canview="/agencies/"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
      </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'
import List from '../../../mixins/List'
export default {
    name: 'PAP',
    mixins: [View, List],
      data: () => ({
        limit: 10,
        page: 0,
        action: '/pap_members',
        columns:[
          {
            id:'id',
            label:'Account ID',
          },
          {
            id:'name',
            label:'Name',
          },
          {
            id:'status',
            label:'Status',
          },
          {
            id:'email',
            label:'Email',
          },
          {
            id:'phone',
            label:'Phone',
          }
        ],
    }),
    computed: {

    },
    props: [],
    methods: {
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
