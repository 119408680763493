<template>
<div class="flex flex-col" >
    <div v-if="submission" class="" >
        <button @click="submission = false" class="bg-red px-4 py-2 border border-red text-white">
            Go Back
        </button>
        <FormSubmission :submission="submission" />
    </div>
    <div v-else class="" >
        <h2 class="text-red text-md">Form Submissions</h2>
        <ul class="p-2" v-for="(submission,i) in submissions" :key="i">
            <li @click="Show(submission)" class="px-4 py-2 cursor-pointer border-b-2 hover:ring focus:ring">
                {{getDate(submission.created_at)}}
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import FormSubmission from './View'
import moment from 'moment'

export default {
    name: 'FormSubmissions',
    data: () => ({
        submissions: [],
        submission: false,
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        getSubscriptions() {
            if(this.model) {
                this.$requests.get(this.model.submissions_route+'/submissions/'+this.model.id,
                {
                    success: (re) => {
                        this.submissions = re.data.items
                        return true
                    },
                })
            }
        },
        Show(item) {
            this.submission = item
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
        this.getSubscriptions()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        FormSubmission,
    },
}
</script>
<style scoped>
</style>
