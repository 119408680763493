<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create Order
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/orders/create" :formdata="formData">
                <SearchSelectInput :disabled="loading_agency" @item:removed="CustomerRemoved" @item:selected="CustomerSelected" :params="customer_search_params" :multiple="false" label="Find Agency By Customer" v-model="item.customer" :optiondisplay="CustomerDisplay" :hideSelected="true"></SearchSelectInput>
                <div v-if="item.customer" class="flex flex-col">
                    <label>Customer Agencies: (Click to assign agency to order)</label>
                    <div class="flex flex-wrap" >
                        <div @click="CustomerAgencySelected(account)" v-for="(account,i) in item.customer.accounts" :key="i" class="flex-1 cursor-pointer bg-yellow px-4 py-2 m-2 text-black" role="button">
                        ID: {{account.id}} Name: {{account.name}}
                        </div>
                    </div>
                </div>
                <SearchSelectInput :disabled="loading_agency" @item:removed="AgencyRemoved" @item:selected="AgencySelected" :params="agency_search_params" :multiple="false" label="Agency" v-model="item.agency" :optiondisplay="AgencyDisplay" :hideSelected="false"></SearchSelectInput>
                <SearchSelectInput @item:selected="ProductSelected" :params="product_search_params" :multiple="true" label="Products" v-model="item.products" :optiondisplay="ProductDisplay" :hideSelected="true"></SearchSelectInput>
                <div v-if="item.products.length" class="flex flex-col">
                    <div class="flex flex-wrap" >
                        <div v-for="(product,i) in item.products" :key="i" class="w-full px-4 py-2 m-2 text-black flex justify-center items-end" role="button">
                            <div class="w-1/2 flex flex-col items-center">
                                <p class="p-2" ><strong>{{product.name}}</strong></p>
                                <!-- <div class="flex flex-col" >
                                    <label>
                                    Apply Coupons To Product 
                                    </label>
                                    <div v-for="(coupon,i) in product.coupons" :key="i" class="flex-1 flex items-center m-2" >
                                        <label :for="'coupon-'+coupon.id">{{coupon.code}}</label>
                                        <input type="checkbox" :id="'coupon-'+coupon.id" :value="coupon.id" v-model="product.selected_coupons">
                                    </div>
                                </div> -->
                            </div>
                            <TextInput class="flex-1" min="1" max="9999" type="number" label="Quantity" v-model="product.quantity" :required="false" ></TextInput>
                            <TextInput class="flex-1" min="0.01" step="0.01" type="number" label="Price" v-model="product.price" :required="false" ></TextInput>
                            <ButtonTemplate style="width:max-content;height:max-content;" :on_click="RemoveProduct" :click_param="product" class="p-2" :disabled="false" type="button">
                                <img style="height:auto;width:20px" src="/assets/trash-white.png" alt="Remove Product">
                            </ButtonTemplate>
                        </div>
                    </div>
                </div>
                <SelectInput @input="UpdatePaymentMethod" label="Payment Method" :required="true" :options="payment_methods"></SelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateOrder',
    mixins: [Create],
    data: () => ({
        back: '/orders',
        timeouts: {
            methods: false
        },
        loading_agency: false,
        payment_methods: [],
        item: {
            agency: false,
            coupons: [],
            products: [],
        },
        customer_search_params: {
            'model': 'Customer',
            'fields': ['id','email','first_name','last_name'],
            'action': '/search/',
            'with': ['accounts'],
        },
        coupon_search_params: {
            'model': 'Coupon',
            'fields': ['id','code'],
            'action': '/search/',
            'with': ['products'],
        },
        agency_search_params: {
            'model': 'Account',
            'fields': ['id','name'],
            'action': '/search/',
        },
        product_search_params: {
            'model': 'Product',
            'fields': ['id','name'],
            'action': '/search/',
            'with': ['coupons'],
        },
        action: '/order/',
    }),
    computed: {
        formData() {
            return {
                'order':this.item,
            }
        },
    },
    props: [],
    methods: {
        GetPaymentMethods() {
            this.$requests.get(`/agency/${this.item.agency.id}/payment_methods`,
            {
                success: (re) => {
                    console.log(re.data)
                    this.payment_methods = re.data.items;
                    return true
                },
            });
        },
        UpdatePaymentMethod(evt) {
            console.log(evt)
        },
        generatePw() {
            this.$set(this.item,'password',Math.random().toString(36).slice(-10))
        },
        AgencyDisplay(item) {
            if(!item) return ''
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        AgencySelected(evt) {
            this.loading_agency = true
            this.$set(this.item,'agency',evt.item)
            if(this.timeouts.methods) clearTimeout(this.timeouts.methods)
            this.timeouts.methods = setTimeout(this.GetPaymentMethods,1000)
        },
        AgencyRemoved(evt) {
            this.$delete(this.item,'agency',false)
            this.payment_methods = []
        },
        CouponDisplay(item) {
            if(!item) return ''
            return 'ID: ' + item.id + ' Code: ' + item.code;
        },
        CouponSelected(evt) {
            this.$set(this.item.coupons,this.item.coupons.length,evt.item)
        },
        CouponRemoved(evt) {
            let index = this.item.coupons.findIndex((coupon) => {return coupon.id == evt.item.id})
            if(index > -1) {
                this.$delete(this.item.coupons,index)
            }
        },
        CustomerSelected(evt) {
            this.$set(this.item,'customer',evt.item)
        },
        CustomerRemoved(evt) {
            this.$delete(this.item,'customer',false)
        },
        CustomerDisplay(item) {
            return 'ID: '+ item.id + 'Email: '+item.email
        },
        CustomerAgencySelected(agency) {
            this.$set(this.item,'agency',agency)
            if(this.timeouts.methods) clearTimeout(this.timeouts.methods)
            this.timeouts.methods = setTimeout(this.GetPaymentMethods,1000)
        },
        ProductDisplay(item) {
            if(!item) return ''
            return 'ID: ' + item.id + ' Name: ' + item.name + ' Price: $'+item.price;
        },
        ProductSelected(evt) {
            evt.item.selected_coupons = []
            this.$set(this.item.products,this.item.products.length,evt.item)
        },
        RemoveProduct(item) {
            let index = this.item.products.findIndex((product) => {return item.id == product.id})
            if(index > -1) this.item.products.splice(index,1)
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
