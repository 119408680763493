<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Order
        </h1>
        <SlideoutTabs default_width="half" :model="{id:item.id,type:'Order',customer_id: item.user_id,coupons_route: '/order/coupons/'+item.id,subscriptions_route:'/order',submissions_route:'/order'}" :contents="tab_content"  orientation="top-0 bottom-0 right-0" position="fixed">
        </SlideoutTabs>
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/order" :formdata="formData">
                <TextInput type="text" label="Total" v-model="item.total" :required="false" ></TextInput>
                <TextInput type="text" label="Status" v-model="item.status" :required="false" ></TextInput>
                <SearchSelectInput @item:clicked="AgencyClick" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="false" label="Agency" v-model="item.agency" :optiondisplay="accountDisplay" :hideSelected="false"></SearchSelectInput>
                <p><strong>Date Created:</strong> {{getDate(item.created_at)}}</p>
                <p><strong>Date Updated:</strong> {{getDate(item.updated_at)}}</p>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'
import Notes from '../../../components/dashboard/Notes'
import PaymentMethods from '../../../components/dashboard/PaymentMethods'
import CustomerList from '../../../components/dashboard/customer/List'
import Subscriptions from '../../../components/dashboard/Subscriptions'
import FormSubmissionList from '../../../components/dashboard/submissions/List'
import Coupons from '../../../components/dashboard/coupons/List'
export default {
    name: 'Order',
    mixins: [View],
    data: () => ({
        parameter: 'order_id',
        action: '/order/',
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name','email','phone'],
            'action': '/search/',
        },
        tab_content: {
            'notes': {
                label: 'Notes',
                icon: '/assets/notes.png',
                component: Notes,
                top: '200px',
            },
            'payment_profiles': {
                label: 'Payment Profile',
                icon: '/assets/payment_profiles.png',
                component: PaymentMethods,
                top: '300px',
            },
            'subscriptions': {
                label: 'Subscriptions',
                icon: '/assets/subscriptions.png',
                component: Subscriptions,
                top: '400px',
            },
            'ordered_by': {
                label: 'Ordered By',
                icon: '/assets/customers.png',
                component: CustomerList,
                top: '500px',
            },
            'form_submissions': {
                label: 'Form Submissions',
                icon: '/assets/formsubmissions.png',
                component: FormSubmissionList,
                top: '600px',
            },
            'coupons': {
                label: 'Coupons',
                icon: '/assets/coupons.png',
                component: Coupons,
                top: '700px',
            },
        }
    }),
    computed: {
        formData() {
            return {
                'order':this.item,
            }
        },
    },
    props: [],
    methods: {
        accountDisplay(item) {
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        accountSelected(evt) {
            let index = this.item.accounts.findIndex((account)=>{return account.id == evt.item.id})
            if(index < 0) this.item.accounts.push(evt.item);
        },
        accountRemoved(evt) {
            this.item.accounts.splice(evt.index,1);
        },
        AgencyClick(evt) {
            this.$router.push({path: `/agencies/${evt.item.id}`})
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
