<template>
<div class="flex flex-col" >
    <div v-if="coupon" class="" >
        <button @click="coupon = false" class="bg-red px-4 py-2 border border-red text-white">
            Go Back
        </button>
        <Coupon :coupon="coupon" />
    </div>
    <div v-else class="" >
        <h2 class="text-red text-md">Coupons</h2>
        <ul class="p-2" v-for="(coupon,i) in coupons" :key="i">
            <li @click="Show(coupon)" class="px-4 py-2 cursor-pointer border-b-2 hover:ring focus:ring">
                {{coupon.code}}
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import Coupon from './View'
import moment from 'moment'

export default {
    name: 'Coupons',
    data: () => ({
        coupons: [],
        coupon: false,
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        getSubscriptions() {
            if(this.model) {
                this.$requests.get(this.model.coupons_route,
                {
                    success: (re) => {
                        this.coupons = re.data.items
                        return true
                    },
                })
            }
        },
        Show(item) {
            this.$router.push({path:'/coupons/'+item.id,target:'_blank'})
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
        this.getSubscriptions()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        Coupon,
    },
}
</script>
<style scoped>
</style>
